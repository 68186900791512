export class strings {
  // public static siteName: string = "Al Hadara";
  public static siteName: string = "Tayar";

  public static itemCreated: string = "Record Saved Successfully!";
  public static itemUpdated: string = "Record Updated Successfully!";
  public static itemDeleted: string = "Record Deleted Successfully!";
  public static itemUnlinked: string = "Record Unlinked Successfully!";

  public static newMaintenanceNotifyTitle: string = "طلب صيانة جديد";
  public static newWarrantyNotifyTitle: string = "طلب كفالة جديد";
  public static changeWarrantyNotifyTitle: string = "تغيير حالة كفالة";
  public static changeMaintenanceNotifyTitle: string = "تغيير حالة صيانة";
  public static newRatingNotifyTitle: string = "تقييم جديد";

  public static newMaintenanceNotifyBody: string = "طلب صيانة جديد رقم";
  public static newWarrantyNotifyBody: string = "طلب كفالة جديد للجهاز ذات الرقم التسلسلي";
  public static changeWarrantyNotifyBody: string = "تم تغيير حالة الكفالة ذات الرقم التسلسلي";
  public static changeMaintenanceNotifyBody: string = "تم تغيير حالة الصيانة ذات الرقم";
  public static newRatingNotifyBodyPrefix: string = "قام";
  public static newRatingNotifyBodySuffix: string = "بتقييم الصيانة رقم";


}
