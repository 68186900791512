import { Injectable, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
//import { Subscription } from 'rxjs/internal/Subscription';

@Injectable({
  providedIn: 'root'
})
export class EventEmitterService {

  //We have defined an EventEmitter variable “invokeFirstComponentFunction” and a Subscription variable “subsVar”.
  //“invokeFirstComponentFunction” variable will be used in another method “onCountryComponentRowCheck” to emit the event and “subsVar” will be used later in event subscription.
  //We can go to “second.component.ts” component and create an event emitter service (country service) variable in the constructor. We will also create a function to invoke the EventEmitter method in service.
  //invokeFirstComponentFunction = new EventEmitter();
  invokeCountryComponentFunction = new EventEmitter();
  invokeCityComponentFunction = new EventEmitter();
  subsVar: Subscription;

  constructor() { }

  //onFirstComponentButtonClick() {
  onCountryComponentRowCheck(itemCode: number) {
    //this.invokeFirstComponentFunction.emit();
    this.invokeCountryComponentFunction.emit(itemCode);
  }

  onCityComponentRowCheck(itemCode1: number, countryCode: number) {
    //this.invokeFirstComponentFunction.emit();
    var data = {
      'cityCode': itemCode1,
      'countryCode': countryCode
    }
    this.invokeCityComponentFunction.emit(data);
  }
}
