export enum routes {
  DASHBOARD = '/dashboard',
  USERS = '/users',
  DEVICES = '/devices',
  WARRANTIES = '/devices-warranty',
  SYSTEMS = '/systems',
  SYSTEMQRS = '/system-qrs',
  MAINTENANCE = '/maintenance',
  SYSTEM_MAINTENANCE = '/system-maintenance',
  ARCHIVED_MAINTENANCE = '/archived-maintenance',
  AGENTS = '/agents',
  ORDERS = '/orders',
  REGION = '/region',
  SECTORS = '/sectors',
  ALERTS = '/alerts',
  SETTINGS = '/settings',
  FIRMWARES = '/firmwares',
  APPVERSIONS = '/app-versions',
  NOTIFICATIONS = '/notifications',
  CONSTANTS = '/constants',
  ROLES = '/roles',
  LOGIN = '/login'
}
