import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

//import libraries for api calls
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

//import {Customer, Employee} from '../models';
import { Country } from '../models';
import { ResponseCls } from 'src/app/common/models/responseCls';
import { consts } from 'src/app/consts';

@Injectable({
  providedIn: 'root'
})
export class CountryService {

  //url = 'http://localhost:59465/Api/Employee';
  //url = consts.API_URI + '/Country';
  // url = consts.Base_URL + '/api/country';
  url = consts.Base_URL + '/countries';

  constructor(private http: HttpClient) { }

  //load static data
  public loadCountriesTableData(): Observable<Country[]> {
    return of([
      { name: 'test@gmail.com', code: 963 },
      { name: 'test2@gmail.com', code: 91 },
      { name: 'test3@gmail.com', code: 1 },
      { name: 'test4@gmail.com', code: 963 },
    ]);
  }

  //get all countrys list from api / from database
  //getAllCountrys(): Observable<Country[]> {
  getAllCountries(token: string): Observable<ResponseCls> {
    let mParams = new HttpParams();

    mParams = mParams.append('limit', '10000');
    mParams = mParams.append('skip', '0');

    // const httpOptions = { headers: new HttpHeaders({ 'Authorization': token }) };
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': token }),
      params: mParams
    };
    //return this.http.get<Country[]>(this.url + '/AllEmployeeDetails');
    //return this.http.get<Country[]>(this.url+'/countrys');
    //return this.http.get<Country[]>(this.url);
    return this.http.get<ResponseCls>(this.url, httpOptions);
  }

  //get object by its id
  getCountryById(countryCode: number, token: string): Observable<Country> {
    const httpOptions = { headers: new HttpHeaders({ 'Authorization': token }) };
    //return this.http.get<Country>(this.url + '/GetEmployeeDetailsById/' + employeeId);
    return this.http.get<Country>(this.url + '/' + countryCode, httpOptions);
  }

  //create new object
  //createCountry(country: Country): Observable<Country> {
  createCountry(country: Country, token: string): Observable<ResponseCls> {
    const httpOptions = { headers: new HttpHeaders({ 'Authorization': token }) };
    //return this.http.post<Country>(this.url + '/InsertDetails/', country, httpOptions);
    //return this.http.post<Country>(this.url , country, httpOptions);
    //return this.http.post<Country>(this.url, country);

    //sending data as json
    return this.http.post<ResponseCls>(this.url, country, httpOptions);

    // return this.http.post<ResponseCls>(this.url, country).subscribe(
    //   data => console.log('success', data),
    //   error => console.log('oops', error)
    // );
  }

  //update object data
  //updateCountry(country: Country): Observable<Country> {
  updateCountry(country: Country, token: string): Observable<ResponseCls> {
    const httpOptions = { headers: new HttpHeaders({ 'Authorization': token }) };
    //return this.http.put<Country>(this.url + '/UpdateCountryDetails/', country, httpOptions);
    //return this.http.put<Country>(this.url + '/', country, httpOptions);
    //return this.http.put<Country>(this.url + '/' + country.id, country);

    //sending data as json
    //return this.http.put<ResponseCls>(this.url + '/' + country.id, country,httpOptions);
    return this.http.put<ResponseCls>(this.url + '/' + country.code, country, httpOptions);
  }

  //delete object from its id
  //deleteCountryById(countryId: number): Observable<number> {
  deleteCountryById(countryCode: number, token: string): Observable<ResponseCls> {
    const httpOptions = { headers: new HttpHeaders({ 'Authorization': token }) };
    //return this.http.delete<number>(this.url + '/DeleteCountryDetails?id=' + countryId, httpOptions);
    //return this.http.delete<number>(this.url + '?id=' + countryId, httpOptions);
    //return this.http.delete<number>(this.url + '/' + countryId, httpOptions);
    return this.http.delete<ResponseCls>(this.url + '/' + countryCode, httpOptions);
  }

}
