import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

//import libraries for api calls
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

//import {Customer, Employee} from '../models';
import { User } from '../models';
import { ResponseCls } from 'src/app/common/models/responseCls';
import { consts, enums } from 'src/app/consts';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public loadUserTableData(): Observable<User[]> {
    return of([
      { name: 'test', phone_number: '0957950098', email: 'test@gmail.com', password: '12345678' },
      { name: 'test2', phone_number: '0957950098', email: 'test2@gmail.com', password: '12345678' },
      { name: 'test3', phone_number: '0957950098', email: 'test3@gmail.com', password: '12345678' },
      { name: 'test4', phone_number: '0957950098', email: 'test4@gmail.com', password: '12345678' },
    ]);
  }
  // public loadUserTableData(): Observable<User[]> {
  //   return of([
  //     {name: 'Joe James', company: 'Example Inc.', city: 'Yonkers', state: 'NY'},
  //     {name: 'John Walsh', company: 'Example Inc.', city: 'Hartford', state: 'CT'},
  //     {name: 'Bob Herm', company: 'Example Inc.', city: 'Tampa', state: 'FL'},
  //     {name: 'James Houston', company: 'Example Inc.', city: 'Dallas', state: 'TX'},
  //     {name: 'Prabhakar Linwood', company: 'Example Inc.', city: 'Hartford', state: 'CT'},
  //     {name: 'Kaui Ignace', company: 'Example Inc.', city: 'Yonkers', state: 'NY'},
  //     {name: 'Esperanza Susanne', company: 'Example Inc.', city: 'Hartford', state: 'CT'},
  //     {name: 'Christian Birgitte', company: 'Example Inc.', city: 'Tampa', state: 'FL'},
  //     {name: 'Meral Elias', company: 'Example Inc.', city: 'Hartford', state: 'CT'},
  //     {name: 'Deep Pau', company: 'Example Inc.', city: 'Yonkers', state: 'NY'},
  //     {name: 'Sebastiana Hani', company: 'Example Inc.', city: 'Dallas', state: 'TX'},
  //     {name: 'Marciano Oihana', company: 'Example Inc.', city: 'Yonkers', state: 'NY'},
  //     {name: 'Brigid Ankur', company: 'Example Inc.', city: 'Dallas', state: 'TX'},
  //     {name: 'Anna Siranush', company: 'Example Inc.', city: 'Yonkers', state: 'NY'},
  //     {name: 'Avram Sylva', company: 'Example Inc.', city: 'Hartford', state: 'CT'},
  //     {name: 'Serafima Babatunde', company: 'Example Inc.', city: 'Tampa', state: 'FL'},
  //     {name: 'Gaston Festus', company: 'Example Inc.', city: 'Tampa', state: 'FL'}
  //   ]);
  // }

  // public loadMaterialTableData(): Observable<Customer[]> {
  //   return of([
  //     {
  //       name: 'Mark Otto',
  //       email: 'ottoto@wxample.com',
  //       product: 'ON the Road',
  //       price: '$25 224.2',
  //       date: '11 May 2017',
  //       city: 'Otsego',
  //       status: 'send'
  //     },
  //     {
  //       name: 'Jacob Thornton',
  //       email: 'thornton@wxample.com',
  //       product: 'HP Core i7',
  //       price: '$1 254.2',
  //       date: '4 Jun 2017',
  //       city: 'Fivepointville',
  //       status: 'send'
  //     },
  //     {
  //       name: 'Larry the Bird',
  //       email: 'bird@wxample.com',
  //       product: 'Air Pro',
  //       price: '$1 570.0',
  //       date: '27 Aug 2017',
  //       city: 'Leadville North',
  //       status: 'pending'
  //     },
  //     {
  //       name: 'Joseph May',
  //       email: 'josephmay@wxample.com',
  //       product: 'Version Control',
  //       price: '$5 224.5',
  //       date: '19 Feb 2018',
  //       city: 'Seaforth',
  //       status: 'declined'
  //     },
  //     {
  //       name: 'Peter Horadnia',
  //       email: 'horadnia@wxample.com',
  //       product: 'Let\'s Dance',
  //       price: '$43 594.7',
  //       date: '1 Mar 2018',
  //       city: 'Hanoverton',
  //       status: 'send'
  //     }
  //   ]);
  // }

  //url = 'http://localhost:59465/Api/Employee';
  //url = consts.API_URI + '/User';
  // url = consts.Base_URL + '/api/users';
  url = consts.Base_URL + '/users';

  constructor(private http: HttpClient) { }

  //getAllUsers(): Observable<User[]> {
  getAllUsers(pageIndex: number, token: string, filters?): Observable<ResponseCls> {
    // const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': token }) };
    //return this.http.get<User[]>(this.url + '/AllEmployeeDetails');
    //return this.http.get<User[]>(this.url+'/users');
    //return this.http.get<User[]>(this.url);
    // return this.http.get<ResponseCls>(this.url + `?page=${pageIndex + 1}`, httpOptions);
    // let filters = {
    //   'name':'',
    //   'email':''
    // }
    let mParams = new HttpParams();
    if (pageIndex == -1) {
      mParams = mParams.append('limit', '10000');
      mParams = mParams.append('skip', '0');
      // return this.http.get<ResponseCls>(this.url + `?limit=1000&skip=0`, httpOptions);
    }
    else {
      mParams = mParams.append('limit', consts.pageSize.toString());
      mParams = mParams.append('skip', (consts.pageSize * pageIndex).toString());
      // mParams = mParams.append('filter', JSON.stringify(filters));
      // mParams = mParams.append('filter[name][contains]', 'mhd');
      // mParams = mParams.append('filter[email][contains]', 'mh');
      if (filters != undefined) {
        Object.keys(filters).forEach(function (key) {
          // mParams = mParams.append(`filter[${key}][contains]`, filters[key]);
          if (filters[key].type == 'string') {//string filter
            if (filters[key].value != '') {
              mParams = mParams.append(`filter[${key}][contains]`, filters[key].value.replace(/\+/gi, ''));
            }
          }
          else if (filters[key].type == 'number') {//number filter
            if (filters[key].value != -1 && filters[key].value.toString() != "") {
              mParams = mParams.append(`filter[${key}]`, filters[key].value);
            }
          } else if (filters[key].type == 'string-enum') {//string enumration filter
            if (filters[key].value != -1) {
              mParams = mParams.append(`filter[${key}][contains]`, filters[key].value);
            }
          } else if (filters[key].type == 'object') {// children filter
            if (filters[key].value != -1) {
              mParams = mParams.append(`filter[${key}][${filters[key].child}][contains]`, filters[key].value); // childern filter >>  filter[device][serial_number][contains] = "5556"
            }
          }
        })
      }
      // return this.http.get<ResponseCls>(this.url + `?limit=${consts.pageSize}&skip=${consts.pageSize * pageIndex}`, httpOptions);
    }
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': token }),
      params: mParams
    };
    return this.http.get<ResponseCls>(this.url, httpOptions);
  }

  getUserById(userId: number, token: string): Observable<User> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': token }) };
    //return this.http.get<User>(this.url + '/GetEmployeeDetailsById/' + employeeId);
    return this.http.get<User>(this.url + '/' + userId, httpOptions);
  }

  //createUser(user: User): Observable<User> {
  createUser(user: User, token: string): Observable<ResponseCls> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': token }) };
    //return this.http.post<User>(this.url + '/InsertUserDetails/', user, httpOptions);
    //return this.http.post<User>(this.url , user, httpOptions);
    //return this.http.post<User>(this.url, user);

    return this.http.post<ResponseCls>(this.url, user, httpOptions);
    // return this.http.post<ResponseCls>(this.url, user).subscribe(
    //   data => console.log('success', data),
    //   error => console.log('oops', error)
    // );
  }

  //updateUser(user: User): Observable<User> {
  updateUser(user: User, token: string): Observable<ResponseCls> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': token }) };
    //return this.http.put<User>(this.url + '/UpdateUserDetails/', user, httpOptions);
    //return this.http.put<User>(this.url + '/', user, httpOptions);
    //return this.http.put<User>(this.url + '/' + user.id, user);
    //return this.http.put<ResponseCls>(this.url + '/' + user.id, user);
    // return this.http.post<ResponseCls>(this.url + '/' + user.id + '?_method=PATCH', user, httpOptions);
    return this.http.put<ResponseCls>(this.url + '/' + user.id, user, httpOptions);

  }

  //deleteUserById(userId: string): Observable<number> {
  deleteUserById(userId: number, token: string): Observable<ResponseCls> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': token }) };
    //return this.http.delete<number>(this.url + '/DeleteUserDetails?id=' + userId, httpOptions);
    //return this.http.delete<number>(this.url + '?id=' + userId, httpOptions);
    //return this.http.delete<number>(this.url + '/' + userId, httpOptions);
    return this.http.delete<ResponseCls>(this.url + '/' + userId, httpOptions);
  }

  getUserDevices(userId: number, token: string, filters?): Observable<ResponseCls> {
    let mParams = new HttpParams();
    // mParams = mParams.append('device_type', device_type);

    if (filters != undefined) {
      Object.keys(filters).forEach(function (key) {
        if (filters[key].type == 'number') {// number filter
          if (filters[key].value != -1 && filters[key].value?.toString() != "" && filters[key].value != undefined) {
            mParams = mParams.append(`[${key}]`, filters[key].value); // number filter >>  filter[status] = 0 or 1 (offline or online)
          }
        } else if (filters[key].type == 'object') {// children filter
          if (filters[key].value != '') {
            mParams = mParams.append(`[${key}][${filters[key].child}]`, filters[key].value); // childern filter >>  filter[device][serial_number][contains] = "5556"
          }
        } else if (filters[key].type == 'in') { //array filter
          filters[key].value.forEach((value, index) => mParams = mParams.append(`[${key}][in][${index}]`, value));
        }
      })
    }

    // const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': token }) };
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': token }),
      params: mParams
    };
    return this.http.get<ResponseCls>(this.url + '/' + userId, httpOptions);
  }

}
