import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

//import libraries for api calls
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

//import {Customer, Employee} from '../models';
import { Maintenance } from '../models';
import { ResponseCls } from 'src/app/common/models/responseCls';
import { consts, enums } from 'src/app/consts';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceService {

  url = consts.Base_URL + '/regulator-maintenance';

  constructor(private http: HttpClient) { }

  getAllMaintenance(pageIndex: number, token: string, filters?): Observable<ResponseCls> {
    let mParams = new HttpParams();
    mParams = mParams.append('limit', consts.pageSize.toString());
    mParams = mParams.append('skip', (consts.pageSize * pageIndex).toString());

    if (filters != undefined) {
      Object.keys(filters).forEach(function (key) {
        if (filters[key].type == 'string') {// string filter
          if (filters[key].value != '' && filters[key].value != undefined) {
            mParams = mParams.append(`filter[${key}][contains]`, filters[key].value); //string filter >> filter[name][contains]="alaa"
          }
        }
        else if (filters[key].type == 'number') {// number filter
          if (filters[key].value != -1 && filters[key].value?.toString() != "" && filters[key].value != undefined) {
            mParams = mParams.append(`filter[${key}]`, filters[key].value); // number filter >>  filter[status] = 0 or 1 (offline or online)
          }
        } else if (filters[key].type == 'string-enum') {// string enumration filter
          if (filters[key].value != -1) {
            mParams = mParams.append(`filter[${key}][contains]`, filters[key].value); // string enumration filter >> filter[sub_type_name][contains] = "6k"
          }
        } else if (filters[key].type == 'in') { //array filter
          filters[key].value.forEach((value, index) => mParams = mParams.append(`filter[${key}][in][${index}]`, value));
        } else if (filters[key].type == 'object') {// children filter
          if (filters[key].value != '') {
            mParams = mParams.append(`filter[${key}][${filters[key].child}][contains]`, filters[key].value); // childern filter >>  filter[device][serial_number][contains] = "5556"
          }
        } else if (filters[key].type == 'object-in') {// array children filter
          if (filters[key].value != '') {
            filters[key].value.forEach((value, index) => mParams = mParams.append(`filter[${key}][${filters[key].child}][in][${index}]`, value));
          }
        } else if (filters[key].type == 'or') {// or filter
          if (filters[key].value.length == 2) {
            //filters[key].value.forEach((value, index) => mParams = mParams.append(`filter[or][${index}][${key}]`, value)); // childern filter >>  filter[device][serial_number][contains] = "5556"
            filters[key].value.forEach((value, index) => mParams = mParams.append(`filter[OR][${index}][${key}]`, value)); // childern filter >>  filter[device][serial_number][contains] = "5556"
          } else if (filters[key].value.length == 1) {
            mParams = mParams.append(`filter[${key}]`, filters[key].value); // number filter >>  filter[status] = 0 or 1 (offline or online)
          } else {
            // no "or" filter specified
          }
        }
        else if (filters[key].type == 'date') {// date filter
          if (filters[key].from != '' && filters[key].from != null) {
            mParams = mParams.append(`filter[${key}][gte]`, filters[key].from);
          }
          if (filters[key].to != '' && filters[key].to != null) {
            mParams = mParams.append(`filter[${key}][lte]`, filters[key].to);
          }
        } else if (filters[key].type == 'date-object') {// date filter
          if (filters[key].from != '' && filters[key].from != null) {
            // mParams = mParams.append(`filter[${key}][gte]`, filters[key].from);
            // filter['steps']['some']['created_at']['gte']: date value
            mParams = mParams.append(`filter[${key}][${filters[key].son}][${filters[key].child}][gte]`, filters[key].from);
          }
          if (filters[key].to != '' && filters[key].to != null) {
            // mParams = mParams.append(`filter[${key}][lte]`, filters[key].to);
            // filter['steps']['some']['created_at']['lte']: date value
            mParams = mParams.append(`filter[${key}][${filters[key].son}][${filters[key].child}][lte]`, filters[key].to);
            mParams = mParams.append(`filter[${key}][${filters[key].son}][type]`, enums.maintenance_status_finished);
          }
        }
      })
    }

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': token }),
      params: mParams
    };
    return this.http.get<ResponseCls>(this.url, httpOptions);
  }

  getMaintenanceById(maintenanceId: number, token: string): Observable<ResponseCls> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': token }) };
    return this.http.get<ResponseCls>(this.url + '/' + maintenanceId, httpOptions);
  }

  createMaintenance(maintenance: Maintenance, token: string): Observable<ResponseCls> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': token }) };
    return this.http.post<ResponseCls>(this.url + '/for-user', maintenance, httpOptions);
  }

  updateMaintenance(maintenance: Maintenance, token: string): Observable<ResponseCls> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': token }) };
    return this.http.put<ResponseCls>(this.url + '/status/' + maintenance.id, maintenance, httpOptions);
  }

  editMaintenance(maintenance: Maintenance, token: string): Observable<ResponseCls> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': token }) };
    return this.http.put<ResponseCls>(this.url + '/' + maintenance.id, maintenance, httpOptions);
  }

  deleteMaintenanceById(maintenanceId: number, token: string): Observable<ResponseCls> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': token }) };
    return this.http.delete<ResponseCls>(this.url + '/' + maintenanceId, httpOptions);
  }

  archiveMaintenance(token: string): Observable<ResponseCls> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': token }) };
    return this.http.post<ResponseCls>(consts.Base_URL + '/maintenance-archives', {}, httpOptions);
  }

  countRequested(token: string, filters): Observable<ResponseCls> {
    // const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': token }) };

    let mParams = new HttpParams();
    // mParams = mParams.append('limit', consts.pageSize.toString());
    // mParams = mParams.append('skip', (consts.pageSize * pageIndex).toString());

    if (filters != undefined) {
      Object.keys(filters).forEach(function (key) {
        if (filters[key].type == 'string') {// string filter
          if (filters[key].value != '' && filters[key].value != undefined) {
            mParams = mParams.append(`filter[${key}][contains]`, filters[key].value); //string filter >> filter[name][contains]="alaa"
          }
        }
        else if (filters[key].type == 'number') {// number filter
          if (filters[key].value != -1 && filters[key].value?.toString() != "" && filters[key].value != undefined) {
            mParams = mParams.append(`filter[${key}]`, filters[key].value); // number filter >>  filter[status] = 0 or 1 (offline or online)
          }
        } else if (filters[key].type == 'string-enum') {// string enumration filter
          if (filters[key].value != -1) {
            mParams = mParams.append(`filter[${key}][contains]`, filters[key].value); // string enumration filter >> filter[sub_type_name][contains] = "6k"
          }
        } else if (filters[key].type == 'in') { //array filter
          filters[key].value.forEach((value, index) => mParams = mParams.append(`filter[${key}][in][${index}]`, value));
        } else if (filters[key].type == 'object') {// children filter
          if (filters[key].value != '') {
            mParams = mParams.append(`filter[${key}][${filters[key].child}][contains]`, filters[key].value); // childern filter >>  filter[device][serial_number][contains] = "5556"
          }
        } else if (filters[key].type == 'object-in') {// array children filter
          if (filters[key].value != '') {
            filters[key].value.forEach((value, index) => mParams = mParams.append(`filter[${key}][${filters[key].child}][in][${index}]`, value));
          }
        } else if (filters[key].type == 'or') {// or filter
          if (filters[key].value.length == 2) {
            //filters[key].value.forEach((value, index) => mParams = mParams.append(`filter[or][${index}][${key}]`, value)); // childern filter >>  filter[device][serial_number][contains] = "5556"
            filters[key].value.forEach((value, index) => mParams = mParams.append(`filter[OR][${index}][${key}]`, value)); // childern filter >>  filter[device][serial_number][contains] = "5556"
          } else if (filters[key].value.length == 1) {
            mParams = mParams.append(`filter[${key}]`, filters[key].value); // number filter >>  filter[status] = 0 or 1 (offline or online)
          } else {
            // no "or" filter specified
          }
        }
        else if (filters[key].type == 'date') {// date filter
          if (filters[key].from != '' && filters[key].from != null) {
            mParams = mParams.append(`filter[${key}][gte]`, filters[key].from);
          }
          if (filters[key].to != '' && filters[key].to != null) {
            mParams = mParams.append(`filter[${key}][lte]`, filters[key].to);
          }
        } else if (filters[key].type == 'date-object') {// date filter
          if (filters[key].from != '' && filters[key].from != null) {
            // mParams = mParams.append(`filter[${key}][gte]`, filters[key].from);
            // filter['steps']['some']['created_at']['gte']: date value
            mParams = mParams.append(`filter[${key}][${filters[key].son}][${filters[key].child}][gte]`, filters[key].from);
          }
          if (filters[key].to != '' && filters[key].to != null) {
            // mParams = mParams.append(`filter[${key}][lte]`, filters[key].to);
            // filter['steps']['some']['created_at']['lte']: date value
            mParams = mParams.append(`filter[${key}][${filters[key].son}][${filters[key].child}][lte]`, filters[key].to);
            // mParams = mParams.append(`filter[${key}][${filters[key].son}][type]`, enums.maintenance_status_finished);
          }
        }
      })
    }

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': token }),
      params: mParams
    };

    return this.http.get<ResponseCls>(this.url + '/count-requested', httpOptions);
  }
}
