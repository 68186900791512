import { MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { environment } from '../../environments/environment';
import { Device } from '../views/device/models';
import { Maintenance } from '../views/maintenance/models';
export class consts {
  public static token: string = null;
  public static firstTime = true;
  public static pageIndex = 0;

  //public static API_URI='http://192.168.1.10:8000/api';
  //public static Base_URL='https://solunyx.com/files/TV-Clock';
  // public static Base_URL='https://solunyx.com/files/alhadara';

  //public static Base_URL='http://5.183.8.231:8100';
  public static Base_URL = environment.apiUrl;

  // table pagination
  public static pageSize = environment.pageSize;//recordsCount per page
  public static pageSizeOptions = environment.pageSizeOptions;

  public static deviceDataSource: MatTableDataSource<Device>;
  public static maintenanceDataSource: MatTableDataSource<Maintenance>

  // notification badges
  public static countPendingWarranty: number = 0;
  public static countRequested: number = 0;
  public static countSysRequested: number = 0;

  public static notificationsCount: number = 0;

  // popup properties
  public static notifyHorizontalPosition: MatSnackBarHorizontalPosition = 'center';
  public static notifyVerticalPosition: MatSnackBarVerticalPosition = 'bottom';
  public static notifyDuration: number = 2000;

  public static toastrOptions = {
    // Time to live in milliseconds
    timeOut: 8000,
    // Time to close after a user hovers over toast
    extendedTimeOut: 8000,
    // Show close button
    // closeButton: true,
    easing: 'ease-out',
    progressBar: true,
    // progressAnimation: 'decreasing',
    positionClass: 'toast-bottom-right'
  };

  public static showSpinner: boolean = false;

  public static notificationHistory: any = [];

  public static overlay_under: boolean = false;

  public static notificationIcon: string = "./assets/main/favicon.ico";

  public static notificationImage: string = "./assets/main/logo.svg";

  public static deviceFilters: any = {};
  public static maintenanceFilters: any = {};
}
