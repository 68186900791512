import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { consts, routes } from "src/app/consts";
import { Device } from "src/app/views/device/models";
import { DeviceService } from "src/app/views/device/services";
import { Maintenance } from "src/app/views/maintenance/models";
import { MaintenanceService } from "src/app/views/maintenance/services";
import { Howl, Howler } from 'howler';
import { ToastrService } from "ngx-toastr";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ResponseCls } from "../models/responseCls";
import { lastValueFrom } from "rxjs";
import { CityService } from "src/app/views/region/services";
import { UserService } from "src/app/views/user/services";

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  sound = new Howl({
    src: ['./assets/notification.mp3'],
    // autoplay: true,
    onend: function () {
      console.log('Finished!');
    }
  });

  // var sound = new Howl({
  //   src: ['sound.webm', 'sound.mp3', 'sound.wav'],
  //   autoplay: true,
  //   loop: true,
  //   volume: 0.5,
  //   onend: function () {
  //     console.log('Finished!');
  //   }
  // });

  constructor(private userService: UserService, private deviceService: DeviceService, private maintenanceService: MaintenanceService, private cityService: CityService, private snackBar: MatSnackBar, private router: Router, private toastr: ToastrService, private http: HttpClient) { }

  public async loadUsers(currentPage: number) {
    consts.showSpinner = true;
    var filters = {};

    var users = [];
    await lastValueFrom(this.userService.getAllUsers(currentPage, consts.token, filters)).then((response) => {
      var userList = response.data.entities;
      console.log("Cities: ", userList);
      if (userList != null) {
        users = userList;
      }
      else {
        users = [];
      }
      consts.showSpinner = false;
    }).catch((err) => {
      this.handleRequestError(err);
    })
    return users;
  }

  public async loadDevicesForAdvancedSearch(currentPage: number) {
    consts.showSpinner = true;
    var filters = {};

    var devices = [];
    await lastValueFrom(this.deviceService.getAllDevices(currentPage, consts.token, filters)).then((response) => {
      var deviceList = response.data.entities;
      console.log("Cities: ", deviceList);
      if (deviceList != null) {
        devices = deviceList;
      }
      else {
        devices = [];
      }
    }).catch((err) => {
      this.handleRequestError(err);
    })
    return devices;
  }

  public loadDevices(pageIndex: number) {
    consts.overlay_under = true;
    consts.showSpinner = true;

    consts.deviceDataSource = new MatTableDataSource<Device>([]);

    this.deviceService.getAllDevices(pageIndex, consts.token, consts.deviceFilters).subscribe({
      next: (response) => {
        var devices = response.data.entities;
        if (devices != null) {
          consts.deviceDataSource = new MatTableDataSource<Device>(devices);
          consts.overlay_under = false;
          consts.showSpinner = false;
        }
        else {
          consts.deviceDataSource = new MatTableDataSource<Device>([]);
          consts.overlay_under = false;
          consts.showSpinner = false;
        }
      },// nextHandler / successHandler
      error: (err) => {
        this.handleRequestError(err, undefined);
        consts.overlay_under = false;
        consts.showSpinner = false;
      },// errorHandler
      complete: () => { }, // completeHandler
    });
  }

  public loadMaintenances(currentPage1: number, filters) {
    consts.overlay_under = true;
    consts.showSpinner = true;

    consts.maintenanceDataSource = new MatTableDataSource<Maintenance>([]);
    this.maintenanceService.getAllMaintenance(currentPage1, consts.token, filters).subscribe({
      next: (response) => {
        var maintenances = response.data.entities;
        if (maintenances != null) {
          consts.maintenanceDataSource = new MatTableDataSource<Maintenance>(maintenances);
          consts.overlay_under = false;
          consts.showSpinner = false;
        }
        else {
          consts.maintenanceDataSource = new MatTableDataSource<Maintenance>([]);
          consts.overlay_under = false;
          consts.showSpinner = false;
        }
      },// nextHandler / successHandler
      error: (err) => {
        this.handleRequestError(err, undefined);
        consts.overlay_under = false;
        consts.showSpinner = false;
      },// errorHandler
      complete: () => { }, // completeHandler
    });
  }

  cities = [];
  countryCode = 963;
  async loadCityList(hideSpinner?: boolean): Promise<Array<any>> {
    consts.showSpinner = true;
    var filters = {};

    await lastValueFrom(this.cityService.getCitiesByCountryCode(this.countryCode, consts.token, filters)).then((response) => {
      var cityList = response.data.entities;
      console.log("Cities: ", cityList);
      if (cityList != null) {
        this.cities = cityList;
      }
      else {
        this.cities = [];
      }
      if (hideSpinner) {
        consts.showSpinner = false;
      }
    }).catch((err) => {
      this.handleRequestError(err);
    })
    return this.cities;
  }

  downloadFile(data: any) {
    //const blob = new Blob([data], { type: 'text/csv' });
    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    window.open(url);
    consts.showSpinner = false;
  }

  uploadFile(file, token: string) {
    const httpOptions = { headers: new HttpHeaders({ 'Authorization': token }) };

    let formData: FormData = new FormData();
    if (file != null) {
      formData.append('file', file);
    }

    //observable response
    // return this.http.post<ResponseCls>(consts.Base_URL + '/media/upload', formData, httpOptions);
    //promise response for await api call to be done
    return this.http.post<ResponseCls>(consts.Base_URL + '/media/upload', formData, httpOptions).toPromise();
  }

  public checkUrl(url: string): boolean {
    if (this.router.url === url) {
      return true;
    }
    return false;
  }

  //navigation to an page
  public navigateTo(url: string) {
    //router.navigate([this.routers.MAINTENANCE]);
    this.router.navigate([url]);
  }

  //reload current component without reload whole page
  public reloadCurrentRoute(url: string) {
    //router.navigate([this.routers.MAINTENANCE]);
    //this.router.navigateByUrl('/maintenance', { skipLocationChange: true }).then(() => {
    this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
      //this.router.navigate(['Your actualComponent']);
      this.router.navigate([url]);
    });
  }

  //reload whole page (current page)
  reloadCurrentPage() {
    window.location.reload();
  }

  dismissDialog(dialogRef) {
    if (dialogRef != undefined) {
      dialogRef.close({ event: 'Save' });
    }
  }

  handleRequestError(err, dialogRef?) {
    consts.showSpinner = false;
    this.notify(err?.error?.message);
    if (err?.error?.details != undefined) {
      this.notify(err?.error?.details[0]);
    }
    if (err?.error?.message == "Unauthenticated." || err?.error?.message == "Unauthorized") {
      if (dialogRef != undefined) {
        this.dismissDialog(dialogRef);
      }
      this.signOut();
    }
  }

  notify(message, notifyDuration: number = 2000) {
    if (message == undefined) {
      message = "An error has occurred. Please try again later.";
    }
    this.snackBar.open(message, 'Close', {
      duration: notifyDuration,
      horizontalPosition: consts.notifyHorizontalPosition,
      verticalPosition: consts.notifyVerticalPosition,
    });
  }

  showSuccess(message, title) {
    this.toastr.success(message, title, consts.toastrOptions)
      .onTap
      .pipe()
      .subscribe(() => this.toasterClickedHandler());
  }

  showError(message, title) {
    this.toastr.error(message, title, consts.toastrOptions)
      .onTap
      .pipe()
      .subscribe(() => this.toasterClickedHandler());
  }

  showInfo(message, title) {
    this.toastr.info(message, title, consts.toastrOptions)
      .onTap
      .pipe()
      .subscribe(() => this.toasterClickedHandler());
  }

  showWarning(message, title) {
    this.toastr.warning(message, title, consts.toastrOptions)
      .onTap
      .pipe()
      .subscribe(() => this.toasterClickedHandler());
  }

  toasterClickedHandler() {
    console.log('Toastr clicked');
  }

  playNotification() {
    this.sound.play();
  }

  // resumeNotification() {
  //   this.sound.resume();
  // }

  stopNotification() {
    this.sound.stop();
  }

  checkNotificationPermission() {
    if (!("Notification" in window)) {
      // Check if the browser supports notifications
      alert("This browser does not support desktop notification");
    } else if (Notification.permission === "granted") {
      // Check whether notification permissions have already been granted;
      // if so, create a notification
      // this.showNotification(title, body, icon, image);
      // …
    } else if (Notification.permission !== "denied") {//if default (Ask for permission)
      // We need to ask the user for permission
      Notification.requestPermission().then((permission) => {
        // If the user accepts, let's create a notification
        if (permission === "granted") {
          // this.showNotification(title, body, icon, image);
        }
      });
    }
    //if permission is denied >> u must go to site settings and change notification permission to ask or allow

    // At last, if the user has denied notifications, and you
    // want to be respectful there is no need to bother them anymore.
  }

  // Windows Pusher Noification
  notifyMe(title, body?, icon?, image?) {
    if (!("Notification" in window)) {
      // Check if the browser supports notifications
      alert("This browser does not support desktop notification");
    } else if (Notification.permission === "granted") {
      // Check whether notification permissions have already been granted;
      // if so, create a notification
      this.showNotification(title, body, icon, image);
      // …
    } else if (Notification.permission !== "denied") {//if default (Ask for permission)
      // We need to ask the user for permission
      Notification.requestPermission().then((permission) => {
        // If the user accepts, let's create a notification
        if (permission === "granted") {
          this.showNotification(title, body, icon, image);
          // …
        }
      });
    }
    //if permission is denied >> u must go to site settings and change notification permission to ask or allow

    // At last, if the user has denied notifications, and you
    // want to be respectful there is no need to bother them anymore.
  }

  showNotification(notificationTitle, notificationBody?, notificationIcon?, notificationImage?) {
    const options = {
      body: notificationBody,
      icon: notificationImage,
      // image: notificationImage,
      silent: true,
      dir: "rtl" as NotificationDirection,
      requireInteraction: true,
    };
    const notification = new Notification(notificationTitle, options);

    notification.addEventListener("click", (event) => {
      window.focus();
      // window.open(location.href)
      notification.close();
    })
  }

  //logout from cpanel
  public signOut(): void {
    var routers: typeof routes = routes;

    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('permissions');

    //router.navigate([routers.LOGIN]);
    this.navigateTo(routers.LOGIN);
  }

  showLoader() {
    setTimeout(() => {
      consts.showSpinner = true;
    }, 0);
  }

  hideLoader() {
    setTimeout(() => {
      consts.showSpinner = false;
    }, 0);
  }
}
