// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  hmr: false,
  siteName: 'Tayar Cpanel',
  // apiUrl: 'http://192.168.88.30:3000',
  // socketUrl: 'http://192.168.88.30:3000/admin',
  // apiUrl: 'https://dev.solunyx.com:3000',
  // socketUrl: 'https://dev.solunyx.com:3000/admin',
  apiUrl: 'https://backend-tayar.solunyx.com:443',
  socketUrl: 'https://backend-tayar.solunyx.com:443/admin',
  // apiUrl: 'https://inofinitycorp.com:3000',
  // socketUrl: 'https://inofinitycorp.com:3000/admin',
  socketOptions: {
    transports: ['polling'],
  },
  pageSize: 25,
  pageSizeOptions: [10, 25, 50, 100]
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
