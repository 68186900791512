import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuard } from './views/auth/guards';
import { DashboardGuard } from './views/auth/guards/dashboard.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: "full"
  },
  {
    path: 'users',
    pathMatch: 'full',
    canActivate: [DashboardGuard],
    loadChildren: () => import('./views/user/user.module').then(m => m.UserModule)
  },
  {
    path: 'devices',
    pathMatch: 'full',
    canActivate: [DashboardGuard],
    loadChildren: () => import('./views/device/device.module').then(m => m.DeviceModule)
  },
  {
    path: 'devices-warranty',
    pathMatch: 'full',
    canActivate: [DashboardGuard],
    loadChildren: () => import('./views/device-warranty/device.module').then(m => m.DeviceModule)
  },
  {
    path: 'systems',
    pathMatch: 'full',
    canActivate: [DashboardGuard],
    loadChildren: () => import('./views/system/system.module').then(m => m.SystemModule)
  },
  {
    path: 'system-qrs',
    pathMatch: 'full',
    canActivate: [DashboardGuard],
    loadChildren: () => import('./views/system-qr/system-qr.module').then(m => m.SystemQRModule)
  },
  {
    path: 'maintenance',
    pathMatch: 'full',
    canActivate: [DashboardGuard],
    loadChildren: () => import('./views/maintenance/maintenance.module').then(m => m.MaintenanceModule)
  },
  {
    path: 'system-maintenance',
    pathMatch: 'full',
    canActivate: [DashboardGuard],
    loadChildren: () => import('./views/maintenance-system/maintenance.module').then(m => m.MaintenanceModule)
  },
  {
    path: 'archived-maintenance',
    pathMatch: 'full',
    canActivate: [DashboardGuard],
    loadChildren: () => import('./views/archived-maintenance/archived-maintenance.module').then(m => m.ArchivedMaintenanceModule)
  },
  {
    path: 'agents',
    pathMatch: 'full',
    canActivate: [DashboardGuard],
    loadChildren: () => import('./views/agent/agent.module').then(m => m.AgentModule)
  },
  {
    path: 'orders',
    pathMatch: 'full',
    canActivate: [DashboardGuard],
    loadChildren: () => import('./views/order/order.module').then(m => m.OrderModule)
  },
  {
    path: 'region',
    pathMatch: 'full',
    canActivate: [DashboardGuard],
    loadChildren: () => import('./views/region/region.module').then(m => m.RegionModule)
  },
  {
    path: 'sectors',
    pathMatch: 'full',
    canActivate: [DashboardGuard],
    loadChildren: () => import('./views/sector/sector.module').then(m => m.RegionModule)
  },
  {
    path: 'firmwares',
    pathMatch: 'full',
    canActivate: [DashboardGuard],
    loadChildren: () => import('./views/firmware/firmware.module').then(m => m.FirmwareModule)
  },
  {
    path: 'app-versions',
    pathMatch: 'full',
    canActivate: [DashboardGuard],
    loadChildren: () => import('./views/app-version/app-version.module').then(m => m.AppVersionModule)
  },
  {
    path: 'notifications',
    pathMatch: 'full',
    canActivate: [DashboardGuard],
    loadChildren: () => import('./views/notifications/notifications.module').then(m => m.NotificationsModule)
  },
  {
    path: 'alerts',
    pathMatch: 'full',
    canActivate: [DashboardGuard],
    loadChildren: () => import('./views/alert/alert.module').then(m => m.AlertModule)
  },
  {
    path: 'constants',
    pathMatch: 'full',
    canActivate: [DashboardGuard],
    loadChildren: () => import('./views/constant/constant.module').then(m => m.ConstantModule)
  },
  {
    path: 'roles',
    pathMatch: 'full',
    canActivate: [DashboardGuard],
    loadChildren: () => import('./views/role/role.module').then(m => m.RoleModule)
  },
  {
    path: 'login',
    canActivate: [AuthGuard],
    loadChildren: () => import('./views/auth/auth.module').then(m => m.AuthModule)
  },

  {
    path: '404',
    // component: NotFoundComponent
    loadChildren: () => import('./views/not-found/not-found.module').then(m => m.NotFoundModule)
  },
  {
    path: '**',
    redirectTo: '404'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      preloadingStrategy: PreloadAllModules,
      relativeLinkResolution: 'legacy'
    })
  ],
  exports: [RouterModule],
  providers: [
    AuthGuard,
    DashboardGuard
  ]
})

export class AppRoutingModule {
}
