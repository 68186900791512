import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

//import libraries for api calls
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

//import {Customer, Employee} from '../models';
import { City } from '../models';
import { ResponseCls } from 'src/app/common/models/responseCls';
import { consts } from 'src/app/consts';

@Injectable({
  providedIn: 'root'
})
export class CityService {

  //url = 'http://localhost:59465/Api/Employee';
  //url = consts.API_URI + '/City';
  // url = consts.Base_URL + '/api/city';
  url = consts.Base_URL + '/cities';

  constructor(private http: HttpClient) { }

  //load static data
  // public loadCitiesTableData(): Observable<City[]> {
  //   return of([
  //     { name: 'test@gmail.com', gmt: 3, timezone: "Asia/Damascus", code: 11, country_id: 1 },
  //     { name: 'test2@gmail.com', gmt: 3, timezone: "Asia/Damascus", code: 11, country_id: 1 },
  //     { name: 'test3@gmail.com', gmt: 3, timezone: "Asia/Damascus", code: 11, country_id: 1 },
  //     { name: 'test4@gmail.com', gmt: 3, timezone: "Asia/Damascus", code: 11, country_id: 1 },
  //   ]);
  // }

  //get all citys list from api / from database
  //getAllCitys(): Observable<City[]> {
  getAllCities(token: string): Observable<ResponseCls> {
    const httpOptions = { headers: new HttpHeaders({ 'Authorization': token }) };
    //return this.http.get<City[]>(this.url + '/AllEmployeeDetails');
    //return this.http.get<City[]>(this.url+'/citys');
    //return this.http.get<City[]>(this.url);
    return this.http.get<ResponseCls>(this.url, httpOptions);
  }

  getCitiesByCountryCode(countryCode: number, token: string, filters?): Observable<ResponseCls> {
    //getCitiesByCountryId(token: string): Observable<ResponseCls> {
    let mParams = new HttpParams();

    mParams = mParams.append('limit', '10000');
    mParams = mParams.append('skip', '0');

    // if (filters != undefined) {
    //   Object.keys(filters).forEach(function (key) {
    //     // mParams = mParams.append(`filter[${key}][contains]`, filters[key]);
    //     if (filters[key].type == 'string') {//string filter
    //       if (filters[key].value != '') {
    //         mParams = mParams.append(`filter[${key}][contains]`, filters[key].value);
    //       }
    //     }
    //     else if (filters[key].type == 'number') {//number filter
    //       if (filters[key].value != -1 && filters[key].value.toString() != "") {
    //         mParams = mParams.append(`filter[${key}]`, filters[key].value);
    //       }
    //     } else if (filters[key].type == 'string-enum') {//string enumration filter
    //       if (filters[key].value != -1) {
    //         mParams = mParams.append(`filter[${key}][contains]`, filters[key].value);
    //       }
    //     }
    //   })
    // }

    // const httpOptions = { headers: new HttpHeaders({ 'Authorization': token }) };
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': token }),
      params: mParams
    };
    //return this.http.get<ResponseCls>(this.url + '/by-country/' + countryId, httpOptions);
    return this.http.get<ResponseCls>(this.url + '/by-country/' + countryCode, httpOptions);
  }

  //get object by its id
  getCityById(cityCode: number, token: string): Observable<City> {
    const httpOptions = { headers: new HttpHeaders({ 'Authorization': token }) };
    //return this.http.get<City>(this.url + '/GetEmployeeDetailsById/' + employeeId);
    return this.http.get<City>(this.url + '/' + cityCode, httpOptions);
  }

  //create new object
  //createCity(city: City): Observable<City> {
  createCity(city: City, token: string): Observable<ResponseCls> {
    const httpOptions = { headers: new HttpHeaders({ 'Authorization': token }) };
    //return this.http.post<City>(this.url + '/InsertDetails/', city, httpOptions);
    //return this.http.post<City>(this.url , city, httpOptions);
    //return this.http.post<City>(this.url, city);

    //sending data as json
    return this.http.post<ResponseCls>(this.url, city, httpOptions);

    // return this.http.post<ResponseCls>(this.url, city).subscribe(
    //   data => console.log('success', data),
    //   error => console.log('oops', error)
    // );
  }

  //update object data
  //updateCity(city: City): Observable<City> {
  updateCity(city: City, cityCode: number, token: string): Observable<ResponseCls> {
    const httpOptions = { headers: new HttpHeaders({ 'Authorization': token }) };
    //return this.http.put<City>(this.url + '/UpdateCityDetails/', city, httpOptions);
    //return this.http.put<City>(this.url + '/', city, httpOptions);
    //return this.http.put<City>(this.url + '/' + city.id, city);

    //sending data as json
    //return this.http.put<ResponseCls>(this.url + '/' + city.code, city, httpOptions);
    return this.http.put<ResponseCls>(this.url + '/' + city.country_code + '/' + cityCode, city, httpOptions);
  }

  //delete object from its id
  //deleteCityById(cityId: number): Observable<number> {
  deleteCityById(countryCode: number, cityCode: number, token: string): Observable<ResponseCls> {
    const httpOptions = { headers: new HttpHeaders({ 'Authorization': token }) };
    //return this.http.delete<number>(this.url + '/DeleteCityDetails?id=' + cityId, httpOptions);
    //return this.http.delete<number>(this.url + '?id=' + cityId, httpOptions);
    //return this.http.delete<number>(this.url + '/' + cityId, httpOptions);
    return this.http.delete<ResponseCls>(this.url + '/' + countryCode + '/' + cityCode, httpOptions);
  }

}
