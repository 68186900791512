import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';

import { consts, routes } from '../../../consts';
import { CommonService } from 'src/app/common/services/common.service';


@Injectable()
export class AuthGuard implements CanActivate {
  public routers: typeof routes = routes;

  constructor(private router: Router, private commonService: CommonService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    consts.token = localStorage.getItem('token');

    if (consts.token) {
      // if has a token to first page
      // this.router.navigate(['/home']);
      this.commonService.navigateTo(this.routers.USERS);
      return false;
    } else {
      //stay in login page
      return true;
    }
    // return true;
  }
}
