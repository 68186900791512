import { ActiveDescendantKeyManager } from "@angular/cdk/a11y";

export class enums {

  public static first_state = -1;
  public static first_state_string = "-1";

  //---------------------------------------------------------------------------

  // Country Code
  public static country_syria = 963;

  // Cities
  public static city_aleppo = 21;
  public static city_raqqa = 22;
  public static city_as_suwayda = 16;
  public static city_damascus = 11;
  public static city_daraa = 15;
  public static city_deir_ez_zor = 51;
  public static city_hama = 33;
  public static city_hasaka = 52;
  public static city_homs = 31;
  public static city_idlib = 23;
  public static city_latakia = 41;
  public static city_quneitra = 14;
  public static city_tartus = 43;

  //---------------------------------------------------------------------------

  // User Roles
  public static role_user = 'USER';//0;
  public static role_admin = 'SUPER_ADMIN';//1;

  // User Activate Status
  public static user_deactivated = 'INACTIVE';//0;
  public static user_activated = 'ACTIVE';//1;
  public static user_pending = 'PENDING';//2;
  // public static user_change_pwd = 'CHANGING_PASSWORD';//3;
  public static user_deleted = 'DELETED';//4;

  //---------------------------------------------------------------------------

  // Device Types
  public static device_type_regulator = 'REGULATOR';//0;
  public static device_type_inverter = 'INVERTER';//1;
  public static device_type_battery = 'BATTERY';//1;
  public static device_type_wifi_module = 'WIFI_MODULE';//1;
  public static device_type_wifi_module_voltronic = 'WIFI_MODULE_VOLTRONIC';//1;
  public static device_type_system = 'INVERTER_SYSTEM';//
  public static device_type_output_module = 'OUTPUT_MODULE';


  //Inverter Types
  public static inverter_type_growatt = 'growatt';
  public static inverter_type_voltronic = 'voltronic';

  //Device Specifications
  public static device_specification_0 = 'UNKNOWN';//0;
  public static device_specification_1 = '80 v / 6000 w';//1;
  public static device_specification_2 = '80 v / 8000 w';//1;
  public static device_specification_3 = '80 v / 10000 w';//1;
  public static device_specification_4 = '80 v / 12000 w';//1;
  public static device_specification_5 = '80 v / 15000 w';//1;
  public static device_specification_6 = '80 v / 20000 w';//1;
  public static device_specification_7 = '80 v / 25000 w';//1;
  public static device_specification_8 = '130 v / 6000 w';//0;
  public static device_specification_9 = '130 v / 8000 w';//1;
  public static device_specification_10 = '130 v / 10000 w';//1;
  public static device_specification_11 = '130 v / 12000 w';//1;
  public static device_specification_12 = '130 v / 15000 w';//1;
  public static device_specification_13 = '130 v / 20000 w';//1;
  public static device_specification_14 = '130 v / 25000 w';//1;
  public static device_specification_15 = '160 v / 6000 w';//0;
  public static device_specification_16 = '160 v / 8000 w';//1;
  public static device_specification_17 = '160 v / 10000 w';//1;
  public static device_specification_18 = '160 v / 12000 w';//1;
  public static device_specification_19 = '160 v / 15000 w';//1;
  public static device_specification_20 = '160 v / 20000 w';//1;
  public static device_specification_21 = '160 v / 25000 w';//1;


  public static device_type_regulator_esp = 'REGULATOR_ESP';
  public static device_type_wifi_module_esp = 'WIFI_MODULE_ESP';
  public static device_type_wifi_module_voltronic_esp = 'WIFI_MODULE_VOLTRONIC_ESP';
  public static device_type_output_module_esp = 'OUTPUT_MODULE_ESP';


  public static end_user_type_ios = 'MOBILE_IOS';
  public static end_user_type_android = 'MOBILE_ANDROID';
  public static end_user_type_tablet = 'TABLET_ANDROID';


  //Regulator Types
  public static regulator_type = {
    static: 'STATIC',
    smart: 'SMART'
  }

  //Device Types
  // public static device_type_normal = 'NORMAL'//0;
  // public static device_type_smart = 'SMART'//1;
  // Product Types
  // public static product_type_regulator = 0; //regulator
  // public static product_type_inverter = 1; //inverter

  // Types
  // public static type_80 = "80";
  // public static type_130 = "130";
  // public static type_160 = "160";
  // public static type_5000 = "5000";
  //Regulator Types (Raise from)
  public static type_80 = 80;
  public static type_130 = 130;
  public static type_160 = 160;
  public static type_3500 = 3500;
  public static type_5000 = 5000;
  public static type_6200 = 6200;
  public static type_11000 = 11000;


  // Sub-Types
  // public static sub_type_6k = '6k';
  // public static sub_type_8k = '8k';
  // public static sub_type_10k = '10k';
  // public static sub_type_12k = '12k';
  // public static sub_type_15k = '15k';
  // public static sub_type_20k = '20k';
  // public static sub_type_25k = '25k';
  //Regulator Power
  public static sub_type_6k = 6000;
  public static sub_type_8k = 8000;
  public static sub_type_10k = 10000;
  public static sub_type_12k = 12000;
  public static sub_type_15k = 15000;
  public static sub_type_20k = 20000;
  public static sub_type_25k = 25000;

  // Warranty States
  public static warranty_state_unknown = 'UNKNOWN';//0;
  public static warranty_state_granted = 'GRANTED';//1;
  public static warranty_state_pending = 'PENDING';//2;
  public static warranty_state_expired = 'EXPIRED';//3;
  public static warranty_state_rejected = 'REJECTED';//4;
  public static warranty_state_smart = 'SMART';//4;

  // Device Statuses
  public static device_status_offline = 'OFFLINE';//0;
  public static device_status_online = 'ONLINE';//1;

  // Battery Types
  public static battery_type = {
    lithium: 'LITHIUM',
  }

  //---------------------------------------------------------------------------

  // Maintenance Statuses
  // public static maintenance_status_pending = 0;
  // public static maintenance_status_received = 1;
  // public static maintenance_status_assigned = 2;
  // public static maintenance_status_rejected = 3;
  // public static maintenance_status_finished = 4;
  // public static maintenance_status_cancelled = 5;

  // Maintenance Steps
  public static maintenance_status_requested = 'REQUESTED';//0;
  public static maintenance_status_received = 'RECEIVED';//1;
  public static maintenance_status_appointment_at = 'APPOINTMENT_AT';//2;
  public static maintenance_status_on_the_way = 'ON_THE_WAY';//3;
  public static maintenance_status_finished = 'FINISHED';//4;
  public static maintenance_status_rated = 'RATED';//5;
  public static maintenance_status_faq_solved = 'FAQ_SOLVED';//5;
  public static maintenance_status_cancelled = 'CANCELLED';//6;

  //---------------------------------------------------------------------------

  // Admin Notification Types
  public static new_maintenance = 'NEW_MAINTENANCE';//0;
  public static new_warranty = 'NEW_WARRANTY';//1;
  public static warranty_change_state = 'WARRANTY_CHANGE_STATE';//2;
  public static maintenance_change_state = 'MAINTENANCE_CHANGE_STATE';//3;
  public static new_rating = 'NEW_RATING';//4;

  //---------------------------------------------------------------------------

  //Notification Types
  public static notification_normal = 'NORMAL';//0;
  public static notification_high_voltage = 'HIGH_VOLTAGE';//1;
  public static notification_low_voltage = 'LOW_VOLTAGE';//2;
  public static notification_overload = 'OVERLOAD';//3;
  public static notification_maintenance = 'MAINTENANCE';//4;
  public static notification_device_started = 'DEVICE_STARTED';//5;
  public static notification_warranty = 'WARRANTY';//6;
  public static notification_maintenance_without_name = 'MAINTENANCE_WITHOUT_NAME';//7;
  public static notification_warranty_deactivated = 'WARRANTY_DEACTIVATED';//8;

  //---------------------------------------------------------------------------

  //Constant Types - Device States
  public static constant_connected_device = 'CONNECTED_DEVICE';//0;
  public static constant_not_connected_device = 'NOT_CONNECTED_DEVICE';//1;
  //Constant Types - Warranty States
  public static constant_warranty_unknown = 'WARRANTY_UNKNOWN';//2;
  public static constant_warranty_granted = 'WARRANTY_GRANTED';//3;
  public static constant_warranty_pending = 'WARRANTY_PENDING';//4;
  public static constant_warranty_expired = 'WARRANTY_EXPIRED';//5;
  public static constant_warranty_smart = 'WARRANTY_SMART';//6;
  //Constant Types - Maintenance Steps
  public static constant_maintenance_requested = 'MAINTENANCE_REQUESTED';//7;
  public static constant_maintenance_received = 'MAINTENANCE_RECEIVED';//8;
  public static constant_maintenance_appoitment = 'MAINTENANCE_APPOINTMENT_AT';//9;
  public static constant_maintenance_on_the_way = 'MAINTENANCE_ON_THE_WAY';//10;
  public static constant_maintenance_finished = 'MAINTENANCE_FINISHED';//11;
  public static constant_maintenance_rated = 'MAINTENANCE_RATED';//12;
  public static constant_maintenance_cancelled = 'MAINTENANCE_CANCELLED';//13;

  //Alert Media Types
  public static alert_media_type = {
    lotti: 'LOTTIE',
    image: 'IMAGE',
    none: 'NONE',
  }

  public static all_permissions = [
    {
      key: "USERS",
      permissions: [
        { permission: "USERS_READ", isSelected: false },
        { permission: "USERS_CREATE", isSelected: false },
        { permission: "USERS_UPDATE", isSelected: false },
        { permission: "USERS_DELETE", isSelected: false },
        { permission: "USERS_READ_ONE", isSelected: false },
      ]
    }, {
      key: "DEVICES",
      permissions: [
        { permission: "DEVICES_READ", isSelected: false },
        { permission: "DEVICES_CREATE", isSelected: false },
        { permission: "DEVICES_UPDATE", isSelected: false },
        { permission: "DEVICES_DELETE", isSelected: false },
        { permission: "DEVICES_READ_ONE", isSelected: false },
        { permission: "DEVICES_CREATE_FROM_EXCEL", isSelected: false },
        { permission: "DEVICES_LINK_TO_USER", isSelected: false },
      ],
    }, {
      key: "WARRANTIES",
      permissions: [
        { permission: "DEVICES_UPDATE_WARRANTY", isSelected: false },
        { permission: "DEVICES_REJECT_WARRANTY", isSelected: false },
        { permission: "DEVICES_COUNT_PENDING_WARRANTY", isSelected: false },
      ],
    }, {
      key: "INVERTER_SYSTEMS",
      permissions: [
        { permission: "INVERTER_SYSTEM_READ", isSelected: false },
        { permission: "INVERTER_SYSTEM_LINK_TO_USER", isSelected: false },
        { permission: "INVERTER_SYSTEM_UNLINK_FROM_USER", isSelected: false },
      ],
    }, {//System QRs
      key: "INVERTER_SYSTEM_COMBINATION",
      permissions: [
        { permission: "INVERTER_SYSTEM_COMBINATION_READ", isSelected: false },
        { permission: "INVERTER_SYSTEM_COMBINATION_CREATE", isSelected: false },
        { permission: "INVERTER_SYSTEM_COMBINATION_UPDATE", isSelected: false },
        { permission: "INVERTER_SYSTEM_COMBINATION_DELETE", isSelected: false },
        { permission: "INVERTER_SYSTEM_COMBINATION_READ_ONE", isSelected: false },
        { permission: "INVERTER_SYSTEM_COMBINATION_CREATE_FROM_EXCEL", isSelected: false },
      ],
    }, {
      key: "MAINTENANCES",
      permissions: [
        { permission: "REGULATOR_MAINTENANCE_READ", isSelected: false },
        { permission: "REGULATOR_MAINTENANCE_CREATE_FOR_USER", isSelected: false },
        { permission: "REGULATOR_MAINTENANCE_UPDATE", isSelected: false },
        { permission: "REGULATOR_MAINTENANCE_COUNT_REQUESTED", isSelected: false },
        { permission: "REGULATOR_MAINTENANCE_CANCEL", isSelected: false },
        { permission: "REGULATOR_MAINTENANCE_READ_ONE", isSelected: false },
        { permission: "REGULATOR_MAINTENANCE_LINK_WITH_DEVICE", isSelected: false },
      ]
    }, {
      key: "SYSTEM_MAINTENANCES",
      permissions: [
        { permission: "INVERTER_SYSTEM_MAINTENANCE_READ", isSelected: false },
        { permission: "INVERTER_SYSTEM_MAINTENANCE_CREATE_FOR_USER", isSelected: false },
        { permission: "INVERTER_SYSTEM_MAINTENANCE_UPDATE", isSelected: false },
        { permission: "INVERTER_SYSTEM_MAINTENANCE_COUNT_REQUESTED", isSelected: false },
        { permission: "INVERTER_SYSTEM_MAINTENANCE_CANCEL", isSelected: false },
        { permission: "INVERTER_SYSTEM_MAINTENANCE_READ_ONE", isSelected: false },
        { permission: "INVERTER_SYSTEM_MAINTENANCE_LINK_WITH_DEVICE", isSelected: false },
      ]
    }, {
      key: "AGENTS",
      permissions: [
        { permission: "AGENTS_READ", isSelected: false },
        { permission: "AGENTS_CREATE", isSelected: false },
        { permission: "AGENTS_UPDATE", isSelected: false },
        { permission: "AGENTS_DELETE", isSelected: false },
        { permission: "AGENTS_READ_ONE", isSelected: false },
      ]
    }, {
      key: "ORDERS",
      permissions: [
        { permission: "ORDERS_READ", isSelected: false },
        { permission: "ORDERS_CREATE", isSelected: false },
        { permission: "ORDERS_UPDATE", isSelected: false },
        { permission: "ORDERS_DELETE", isSelected: false },
        { permission: "ORDERS_READ_ONE", isSelected: false },
        { permission: "ORDERS_CREATE_BY_EXCEL", isSelected: false },
      ]
    }, {
      key: "COUNTRIES",
      permissions: [
        { permission: "COUNTRIES_CREATE", isSelected: false },
        { permission: "COUNTRIES_UPDATE", isSelected: false },
        { permission: "COUNTRIES_DELETE", isSelected: false },
      ]
    }, {
      key: "STATES",
      permissions: [
        { permission: "CITIES_READ", isSelected: false },
        { permission: "CITIES_CREATE", isSelected: false },
        { permission: "CITIES_UPDATE", isSelected: false },
        { permission: "CITIES_DELETE", isSelected: false },
        { permission: "CITIES_READ_BY_COUNTRY", isSelected: false },
        { permission: "CITIES_READ_ALL", isSelected: false },
      ]
    }, {
      key: "SECTORS",
      permissions: [
        { permission: "SECTORS_READ", isSelected: false },
        { permission: "SECTORS_CREATE", isSelected: false },
        { permission: "SECTORS_UPDATE", isSelected: false },
        { permission: "SECTORS_DELETE", isSelected: false },
        { permission: "SECTORS_READ_ONE", isSelected: false },
        { permission: "SECTORS_UNLINK_ZONE", isSelected: false },
      ]
    }, {
      key: "ZONES",
      permissions: [
        { permission: "ZONES_READ", isSelected: false },
        { permission: "ZONES_CREATE", isSelected: false },
        { permission: "ZONES_UPDATE", isSelected: false },
        { permission: "ZONES_DELETE", isSelected: false },
        { permission: "ZONES_READ_BY_COUNTRY", isSelected: false },
      ]
    }, {
      key: "ALERTS",
      permissions: [
        { permission: "ALERTS_READ", isSelected: false },
        { permission: "ALERTS_CREATE", isSelected: false },
        { permission: "ALERTS_UPDATE", isSelected: false },
        { permission: "ALERTS_DELETE", isSelected: false },
        { permission: "ALERTS_READ_ONE", isSelected: false },
      ]
    }, {
      key: "CLIENTS_VERSIONS",
      permissions: [
        { permission: "CLIENTS_VERSIONS_READ_HARDWARE", isSelected: false },
        { permission: "CLIENTS_VERSIONS_READ_END_USER", isSelected: false },
        { permission: "CLIENTS_VERSIONS_CREATE_HARDWARE", isSelected: false },
        { permission: "CLIENTS_VERSIONS_CREATE_END_USER", isSelected: false },
        { permission: "CLIENTS_VERSIONS_UPDATE_HARDWARE", isSelected: false },
        { permission: "CLIENTS_VERSIONS_UPDATE_END_USER", isSelected: false },
        { permission: "CLIENTS_VERSIONS_DELETE", isSelected: false },
        { permission: "CLIENTS_VERSIONS_READ_ONE", isSelected: false },
        { permission: "CLIENTS_VERSIONS_DOWNLOAD_FIRMWARE", isSelected: false },
      ]
    }, {
      key: "CONSTANTS",
      permissions: [
        { permission: "CONSTANTS_READ", isSelected: false },
        { permission: "CONSTANTS_UPDATE", isSelected: false },
      ]
    }, {
      key: "NOTIFICATIONS",
      permissions: [
        { permission: "NOTIFICATION_CONTENT_READ", isSelected: false },
        { permission: "NOTIFICATION_CONTENT_UPDATE", isSelected: false },
      ]
    }, {
      key: "FEEDBACKS",
      permissions: [
        { permission: "FEEDBACKS_READ", isSelected: false },
        { permission: "FEEDBACKS_DELETE", isSelected: false },
        { permission: "FEEDBACKS_REPLY", isSelected: false },
      ]
    }, {
      key: "REPORTS",
      permissions: [
        { permission: "REPORTS_READ_USERS", isSelected: false },
        { permission: "REPORTS_READ_DEVICES", isSelected: false },
        { permission: "REPORTS_READ_MAINTENANCES", isSelected: false },
        { permission: "REPORTS_READ_AGENTS", isSelected: false },
        { permission: "REPORTS_READ_ORDERS", isSelected: false },
      ]
    }, {
      key: "ROLES",
      permissions: [
        { permission: "ROLE_READ", isSelected: false },
        { permission: "ROLE_CREATE", isSelected: false },
        { permission: "ROLE_UPDATE", isSelected: false },
        { permission: "ROLE_DELETE", isSelected: false },
      ]
    }
  ];
}


